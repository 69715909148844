.parallax-container {
    min-height: 550px;
    line-height: 0;
    height: auto;
    text-shadow: 2px 2px 2px rgba(0,0,0,1);
}

.slides{
    text-shadow: 2px 2px 2px rgba(0,0,0,1)
}


.slider .indicators .indicator-item {
    background-color: transparent;
    border: 1px solid #9e9e9e ;
}

.slider .indicators .indicator-item.active {
    background-color: #9e9e9e;
}

nav{
    opacity:0.97;
    -moz-opacity: 0.97;
    filter: alpha(opacity=97);
}

nav ul a,
nav .brand-logo {
    color: #444;
}

#adm-nav nav ul a.active{
    background: #1565c0;
}


p {
    line-height: 2rem;
}

@media only screen and (max-width : 992px) {
    .parallax-container .section {
        position: absolute;
        top: 40%;
    }
    #index-banner .section {
        top: 10%;
    }
}

@media only screen and (max-width : 600px) {
    #index-banner .section {
        top: 0;
    }
}

.icon-block {
    padding: 0 15px;
}
.icon-block .material-icons {
    font-size: inherit;
}

footer.page-footer {
    margin: 0;
}

.card .card-title{
    text-shadow: 2px 2px 2px rgba(0,0,0,2);
}
/****************************************/

#index-banner h1{
    margin-top: 150px;
}

#sobre{
    background-image: url("/img/logo-bgd.png");
    background-position: center;
    background-repeat: no-repeat;

}

#sobre .icon-block .logo-mini{
    margin-bottom: 0px;
}

#sobre .icon-block .title{
    margin-top: 0px;
    padding-top: 0px
}

/******** ADM ****************/
.adm-content{
    min-height: 450px;
}

.input-field label {
    color: #424242;
}

input, optgroup, select, textarea {
    color: #000;
}

.msg_alert{
    position: fixed;
    z-index: 9999;
    width: auto;
    padding: 10px 20px;
    bottom: 0px;
    right: 0px;
}

#service-images .service-card{
    height: 200px;
}

#service-images .card .card-image img{
    height: 150px;
}